import React, {memo, useMemo, useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../CustomIcon";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import {useNavigate, useLocation} from "react-router-dom";
import {joinChat} from "@/utils/common";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import {
    getMemeTgeNoticeList
} from "@/utils/memeClub";
import {
    gameMemeClubAssetsPath,
    gameMemeClubPath,
    gameMemeClubTgePath,
    gameMemeClubLauncherPath,
    gameMomentClubExplorePath,
    gameMomentClubLauncherPath,
    gameMomentClubPointsPath,
    gameMomentClubTgePath,
    gameMomentClubAssetsPath,
    gameMomentClubCreatPath,
    gameMomentTiktokSelectPath,
    linkPath,
} from "@/routes/config";
import { message } from "antd";
import { getMomentTgeNoticeList } from "@/utils/momentClub";
import { AutoStrangeRule } from "@/utils/strangeRule";

const GameMemeClubNavs = (
    {
        userInfo,
        setShowBalance,
        momentAssetUnpackList,
        setMomentAssetUnpackList,
        momentAssetUnpackListQueryLastTime,
        setMomentAssetUnpackListQueryLastTime,
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const containerRef = useRef(null);
    const [btnCenterLock, setBtnCenterLock] = useState(false);
    const [showCreatePanel, setShowCreatePanel] = useState(false);
    const {
        userId, 
        selfData,
        platformData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        buyFirstKey, 
        payEntryFee, 
        task,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const {contextSafe} = useGSAP(
        () => {
        },
        { scope: containerRef }
    );


    const {t, i18n} = useTranslation();
    const [queryMemeAssetUnpackListCount, setQueryMemeAssetUnpackListCount] = useState(0);
    const navArr = [
        {
            id: 0,
            icon: 'Button/UI_Button-D_EXPORE',
            label: 'POINTS',
            labelName: AutoStrangeRule(t('POINTS')),
            path: gameMomentClubPointsPath
        },
        {
            id: 1,
            icon: 'Button/UI_Button-D_Ecosystem',
            label: 'Explore',
            labelName: AutoStrangeRule(t('EXPLORE')),
            path: gameMomentClubExplorePath
        }, 
        {
            id: 2,
            icon: 'Button/UI_Button-D_Leadership',
            label: 'TGE Meme',
            labelName: AutoStrangeRule(t('TGE Meme')),
            path: gameMomentClubTgePath
        }, 
        {
            id: 3,
            icon: 'Button/UI_Button-D_Assets',
            label: 'Profile',
            labelName: AutoStrangeRule(t('PROFILE')),
            path: gameMomentClubAssetsPath
        }
    ]

    useEffect(() => {
        let nowTime = new Date().getTime();
        if (nowTime - momentAssetUnpackListQueryLastTime >= 60000){
            setMomentAssetUnpackListQueryLastTime(nowTime);
            getMomentTgeNoticeList().then(res => {
                if (res?.noticeList?.length > 0){
                    setMomentAssetUnpackList(res?.noticeList);
                }
                else{
                    setMomentAssetUnpackList(null);
                }
            }).catch(e => {
                console.error(e);
            })
        }
        setTimeout(() => {
            setQueryMemeAssetUnpackListCount(queryMemeAssetUnpackListCount + 1);
        }, 10000);
    }, [queryMemeAssetUnpackListCount]);

    const unreadMemeAssetNewPackNum = useMemo(() =>{
        let res = 0;
        if (momentAssetUnpackList?.length > 0){
            for (let i = 0; i < momentAssetUnpackList?.length; i++){
                if (!momentAssetUnpackList[i].isRead){
                    res++;
                }
            }
        }
        return res;
    }, [momentAssetUnpackList])

    const linkToPage = (link, label) => {
        navigate(link);
    }

    const playSwitchCreatePanel = contextSafe(() => {
        if (btnCenterLock){
            return;
        }
        setBtnCenterLock(true);
        let tl = gsap.timeline();
        if (showCreatePanel){
            tl.to('.btnCenter', {
                duration: 0.2,
                rotate: '0deg',
                onComplete: () =>{
                    setShowCreatePanel(false);
                    setBtnCenterLock(false);
                }
            });
            tl.to('.createPanel', {
                duration: 0.2,
                top: '20px',
            }, "<");
            tl.to('.createPanel', {
                duration: 0.1,
                autoAlpha: 0,
            }, "<");
        }
        else{
            tl.to('.btnCenter', {
                duration: 0.2,
                rotate: '45deg',
                onComplete: () =>{
                    setShowCreatePanel(true);
                    setBtnCenterLock(false);
                }
            });
            tl.to('.createPanel', {
                duration: 0.2,
                top: '-20px',
            }, "<");
            tl.to('.createPanel', {
                duration: 0.1,
                autoAlpha: 1,
            }, "<");
        }
    });

    const getNavButton = (index) =>{
        if (navArr?.[index]){
            return (
                <div className={`${s.item} ${location.pathname === navArr[index].path ? s.active : ''}`}
                        onClick={() => {
                            // if(navArr[index].path === gameMomentClubLauncherPath){
                            //     message.info({content:`Coming Soon`})
                            //     return
                            // }
                            linkToPage(navArr[index].path, navArr[index].label)
                        }}>
                    <div className={`${s.iconWrap}`}>
                        <CustomIcon
                            width={32} height={32}
                            imgName={`${location.pathname === navArr[index].path ? navArr[index].icon + '_On' : navArr[index].icon + '_D'}`}
                            />
                    </div>
                    <div className={`${s.label} momentFont`}>
                        {navArr[index].labelName}
                    </div>
                    {
                        (navArr[index]?.id === 3 && unreadMemeAssetNewPackNum > 0) &&
                        <>
                            <div className={`${s.memeAssetRedPoint} flex_center_center`}>
                                <span className={`${s.memeAssetRedPointText}`}>
                                    {unreadMemeAssetNewPackNum > 99 ? '99+':unreadMemeAssetNewPackNum}
                                </span>
                            </div>
                        </>
                    }
                </div>
            )
        }
        else{
            return (<></>);
        }
    }

    return (
        <div className={s.footNavWrap} ref={containerRef}>
            <div className={`${s.footNav} flex_center_between`}>
                <div className={`${s.footNavLeft}`}>
                    {
                        getNavButton(0)
                    }
                    {
                        getNavButton(1)
                    }
                </div>
                <div className={`${s.footNavCenter}`}>
                    <div className={`${s.createPanel} flex_center_center_col createPanel`} onClick={() => {
                        linkToPage(`${gameMomentClubCreatPath}/${1}`);
                    }}>
                        {/*<div className={`${s.createPanelItem} flex_center_center`} onClick={() =>{   
                            if (platformData?.platformMap?.[2]){
                                linkToPage(gameMomentTiktokSelectPath);
                            }
                            else{
                                navigate(`${linkPath}/${2}`);
                            }
                        }}>
                            <div className={`${s.upload}`}>
                                {t('Link Tiktok Video')}
                            </div>
                        </div>
                        <div className={`${s.createPanelLine}`}>
                        </div>*/}
                        <div className={`${s.createPanelItem} flex_center_center`} onClick={() =>{
                            linkToPage(`${gameMomentClubCreatPath}/${1}`);
                        }}>
                            <div className={`${s.upload}`}>
                                {t('Upload Local Video')}
                            </div>
                        </div>
                    </div>
                    <div className={`${s.showUploadBtn} flex_center_center showUploadBtn`} style={{backgroundImage: `url(/UI/Button/UI_Button_Explore_Create_01.png)`}}
                        onClick={() =>{
                            //playSwitchCreatePanel();
                            linkToPage(`${gameMomentClubCreatPath}/${1}`);
                        }}>
                        {/*<div className={`${s.btnCenter} ${s.btnCenterRotate} btnCenter`} style={{backgroundImage: `url(/UI/Button/UI_Button_Create_01.png)`}}>
                        </div>*/}
                    </div>
                </div>
                <div className={`${s.footNavRight}`}>
                    {
                        getNavButton(2)
                    }
                    {
                        getNavButton(3)
                    }
                </div>
            </div>
            {/*<div className={s.footNav}>
                {
                    navArr.map((item, ind) => {
                        return (
                            <div className={`${s.item} ${location.pathname === item.path ? s.active : ''}`} key={ind}
                                 onClick={() => {
                                     linkToPage(item.path, item.label)
                                 }}>
                                <div className={`${s.iconWrap}`}>
                                    <CustomIcon
                                        width={32} height={32}
                                        imgName={`${location.pathname === item.path ? item.icon + '_On' : item.icon}`}
                                        />
                                </div>
                                <div className={`${s.label} fb`}>{item.labelName}</div>
                                {
                                    (item?.id === 2 && unreadMemeAssetNewPackNum > 0) &&
                                    <>
                                        <div className={`${s.memeAssetRedPoint} flex_center_center`}>
                                            <span className={`${s.memeAssetRedPointText}`}>
                                                {unreadMemeAssetNewPackNum > 99 ? '99+':unreadMemeAssetNewPackNum}
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    })
                }
            </div>*/}
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance,
        momentAssetUnpackList: app.momentAssetUnpackList,
        momentAssetUnpackListQueryLastTime: app.momentAssetUnpackListQueryLastTime,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
        setMomentAssetUnpackList: (params) => {
            return dispatch({
                type: "app/setMomentAssetUnpackList",
                payload: params,
            });
        },
        setMomentAssetUnpackListQueryLastTime: (params) => {
            return dispatch({
                type: "app/setMomentAssetUnpackListQueryLastTime",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GameMemeClubNavs));
