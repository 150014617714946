import { BigNumber, ethers } from "ethers";
import { abi_momentClub, abi_token } from "@/utils/abi";
import { getConfig } from "./common";
import { getGasConfig } from "@/utils/gas";
import momentChainToken from "@/utils/json/momentChainToken.json";
import { getLfgAllowance } from "@/utils/bidNftWeb3";
import { isOnlineEnv, LfgMainnetId, LfgTestnetId } from "@/utils/env";
import { getWeb3Config } from "@/utils/common";
import { lfgApprove } from "@/utils/lfgStake";
import { erc721Abi, parseAbi } from "viem";
import { getGeneralPaymasterInput } from "viem/zksync";

import { generatePrivateKey, privateKeyToAccount } from "viem/accounts";
import { LimitType } from "@abstract-foundation/agw-client/sessions";
import { toFunctionSelector, toBytes, parseEther } from "viem";
import { abstract } from "viem/chains";
import { trace } from "superagent";

function stringToHex(str) {
  const val = [...str].map((c) =>
    c
      .charCodeAt(0)
      .toString(16)
      .padStart(2, 0)
  ).join``;
  return "0x" + val;
}

function limitDecimals(value, decimals) {
  const parts = value.split(".");
  console.trace("limitDecimals", { value, decimals, parts });
  if (parts.length === 2 && parts[1].length > decimals) {
    parts[1] = parts[1].slice(0, decimals);
    return parts.join(".");
  }
  return value;
}

async function getmomentClubContract(wallets, coinId, isWriter) {
  const { chainId } = momentChainToken.find((i) => i?.ID === coinId);
  const web3Config = await getWeb3Config();
  const contractInfo = web3Config?.contractList?.find(
    (item) => item.coinId === coinId
  );
  let momentFactoryContract = contractInfo?.momentFactoryContract;
  console.log(
    "[momentClub contract Addr]",
    momentFactoryContract,
    "[chainId]",
    chainId
  );
  //console.log(embeddedWallet);

  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  await embeddedWallet.switchChain(chainId);
  const privyProvider = await embeddedWallet.getEthereumProvider();
  const provider = new ethers.providers.Web3Provider(privyProvider);

  isWriter = false; //Since USE AGW, we don't need to sign the transaction by privy
  if (isWriter) {
    const signer = provider.getSigner();

    const gasConfig = await getGasConfig(signer);
    gasConfig.gasLimit = chainId === 11124 ? 100000000 : 25000000;

    const contract = new ethers.Contract(
      momentFactoryContract,
      abi_momentClub,
      provider
    ).connect(signer);
    return {
      contract,
      addr: embeddedWallet.address,
      gasConfig,
      embeddedWallet,
      coinContract: contractInfo?.coinContract,
      contractInfo,
    };
  } else {
    const contract = new ethers.Contract(
      momentFactoryContract,
      abi_momentClub,
      provider
    );
    return {
      contract,
      addr: embeddedWallet.address,
      embeddedWallet,
      coinContract: contractInfo?.coinContract,
      contractInfo,
    };
  }
}

const mintMomentToken = ({
  wallets,
  clubId,
  cardArr,
  tgeType,
  amountArr,
  amount,
  coinId,
  sendTransaction,
  agwAddress,
  agwClient,
  createSessionAsync,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { contract, addr, gasConfig } = await getmomentClubContract(
        wallets,
        coinId,
        true
      );

      console.log("mintMomentTokenParam", {
        contract: contract,
        addr: addr,
        clubId: clubId,
        cardArr: cardArr,
        amountArr: amountArr,
        gasConfig: gasConfig,
      });

      /*let newAmountArr = [];
            if (amountArr?.length > 0){
                for (let i = 0; i < amountArr?.length; i++){
                    newAmountArr.push(ethers.utils.parseEther(amountArr[i].toString()));
                }
            }
            console.log("newAmountArr", newAmountArr, amountArr);*/
      if (tgeType === 1) {
        contract.populateTransaction
          .mintDrawMoment(
            ethers.BigNumber.from(clubId),
            cardArr,
            amountArr,
            gasConfig
          )
          .then((unsignedTx) => {
            const { abi, functionName, args } = {
              abi: "",
              functionName: "mintDrawMoment",
              args: [ethers.BigNumber.from(clubId), cardArr, amountArr],
            };

            sendUnsignTransactionByAgw(
              unsignedTx,
              sendTransaction,
              wallets,
              agwAddress,
              agwClient,
              createSessionAsync,
              abi,
              functionName,
              args
            )
              .then((resp) => {
                console.log("[mintMomentToken] ", resp);
                resolve(resp);
              })
              .catch((e) => {
                console.log("[mintMomentToken exception]", e);
                if (
                  e.message.indexOf("User already has an account") > 0 ||
                  e.message.indexOf("(reading 'switchChain')") > 0
                ) {
                  console.log("privy need login");
                  reject("need login");
                } else {
                  reject("Transaction Failed");
                }
              });
          })
          .catch((e) => {
            console.log("[mintMomentToken exception]", e);
            if (
              e.message.indexOf("User already has an account") > 0 ||
              e.message.indexOf("(reading 'switchChain')") > 0
            ) {
              console.log("privy need login");
              reject("need login");
            } else {
              reject("Transaction Failed");
            }
          });
      } else {
        contract.populateTransaction
          .mintFairMoment(ethers.BigNumber.from(clubId), amount, gasConfig)
          .then((unsignedTx) => {
            const { abi, functionName, args } = {
              abi: "",
              functionName: "mintFairMoment",
              args: [ethers.BigNumber.from(clubId), amount],
            };

            sendUnsignTransactionByAgw(
              unsignedTx,
              sendTransaction,
              wallets,
              agwAddress,
              agwClient,
              createSessionAsync,
              abi,
              functionName,
              args
            )
              .then((resp) => {
                console.log("[mintMomentToken] ", resp);
                resolve(resp);
              })
              .catch((e) => {
                console.log("[mintMomentToken exception]", e);
                if (
                  e.message.indexOf("User already has an account") > 0 ||
                  e.message.indexOf("(reading 'switchChain')") > 0
                ) {
                  console.log("privy need login");
                  reject("need login");
                } else {
                  reject("Transaction Failed");
                }
              });
          })
          .catch((e) => {
            console.log("[mintMomentToken exception]", e);
            if (
              e.message.indexOf("User already has an account") > 0 ||
              e.message.indexOf("(reading 'switchChain')") > 0
            ) {
              console.log("privy need login");
              reject("need login");
            } else {
              reject("Transaction Failed");
            }
          });
      }
    } catch (error) {
      console.log("[mintMomentToken exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

const checkTransaction = (wallets, transactionHash, chainId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
      );
      await embeddedWallet.switchChain(chainId);

      const privyProvider = await embeddedWallet.getEthereumProvider();
      const provider = new ethers.providers.Web3Provider(privyProvider);

      const transaction = await provider.getTransaction(transactionHash);

      console.log("checkNftInfo transaction", transaction);

      resolve(transaction);
    } catch (error) {
      console.log("[checkTransaction exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

const getAssetTransfers = (wallets, chainId, transaction) => {
  return new Promise(async (resolve, reject) => {
    try {
      const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
      );
      await embeddedWallet.switchChain(chainId);

      const privyProvider = await embeddedWallet.getEthereumProvider();
      const provider = new ethers.providers.Web3Provider(privyProvider);

      const blockNumberString =
        "0x" + transaction.blockNumber.toString(16).toUpperCase();
      const result = await provider.send("alchemy_getAssetTransfers", [
        {
          category: ["erc20", "erc721"],
          toAddress: transaction.from.toString(),
          fromBlock: blockNumberString,
          toBlock: blockNumberString,
        },
      ]);
      resolve(result);
    } catch (error) {
      console.log("[getAssetTransfers exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

const getNftMetadata = (wallets, contractAddr, tokenId, chainId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
      );
      await embeddedWallet.switchChain(chainId);

      const privyProvider = await embeddedWallet.getEthereumProvider();
      const provider = new ethers.providers.Web3Provider(privyProvider);

      const contract = new ethers.Contract(contractAddr, erc721Abi, provider);

      contract
        .tokenURI(tokenId)
        .then((res) => {
          console.log("[getNftMetadata] ", res);
          resolve(res);
        })
        .catch((e) => {
          console.log("[getNftMetadata exception]", e);
          if (
            e.message.indexOf("User already has an account") > 0 ||
            e.message.indexOf("(reading 'switchChain')") > 0
          ) {
            console.log("privy need login");
            reject("need login");
          } else {
            reject("Transaction Failed");
          }
        });
    } catch (error) {
      console.log("[getNftMetadata exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

const generateSessionSignerAgw = (agwAddress) => {
  const sessionPrivateKey = generatePrivateKey();
  localStorage.setItem("spk_" + agwAddress, sessionPrivateKey);

  const sessionSigner = privateKeyToAccount(sessionPrivateKey);
  //localStorage.setItem("ss_" + agwAddress, JSON.stringify(sessionSigner));

  //console.log({ agwAddress, sessionPrivateKey, sessionSigner });

  //let exp = Math.floor(Date.now() / 1000) + 7 * 24 * 3600; // 7 * 24 hours
  //localStorage.setItem("sexp_" + agwAddress, exp);

  return sessionSigner;
};

const fillCallPolicies = (targetArray, abiString) => {
  const target = "0xc891eC391CA792b69DF4A3ce2EB0Fa820b03a5C5"; // Contract address
  const selector = toFunctionSelector(abiString); // Allowed function
  //console.log('selector for ' + abiString + ' is ' + selector);
  targetArray.push({
    target,
    selector,
    valueLimit: {
      limitType: LimitType.Unlimited,
      limit: BigInt(0),
      period: BigInt(0),
    },
    maxValuePerUse: parseEther("9999"),
    constraints: [],
  });
};

const generateSessionConfigAgw = (sessionSigner, exp) => {
  let callPolicies = [];

  fillCallPolicies(
    callPolicies,
    "buyFairCard(uint256 clubId, uint256 amount, uint256 expectedPrice)"
  );

  fillCallPolicies(
    callPolicies,
    "buyDrawCard(uint256 clubId, uint256 amount, uint256 expectedPrice) payable"
  );

  fillCallPolicies(callPolicies, "mintFairMoment(uint256, uint256)");
  fillCallPolicies(
    callPolicies,
    "mintDrawMoment(uint256, uint256[], uint256[])"
  );

  fillCallPolicies(
    callPolicies,
    "sellFairCard(uint256 clubId, uint256 amount)"
  );

  fillCallPolicies(
    callPolicies,
    "sellDrawCard(uint256 clubId, uint256[] calldata cardArr, uint256[] calldata amountArr)"
  );

  fillCallPolicies(
    callPolicies,
    "newMomentClub(uint256,uint256,uint256,tuple(uint8,uint256,uint256,uint256,string,string,string,uint8,uint256,uint256) calldata,uint256,bytes calldata)"
  );
  callPolicies[callPolicies.length - 1].selector = "0x70f47363"; //fix the selector for newMomentClub

  const session = {
    signer: sessionSigner.address,
    expiresAt: BigInt(exp),
    feeLimit: {
      limitType: LimitType.Lifetime,
      limit: parseEther("1"), // 1 ETH lifetime gas limit
      period: BigInt(0),
    },
    callPolicies,
    transferPolicies: [],
  };
  return session;
};

const retrieveSessionSignerAgw = (agwAddress) => {
  const sessionPrivateKey = localStorage.getItem("spk_" + agwAddress);
  if (!sessionPrivateKey || sessionPrivateKey === "") {
    console.log("Session Private Key not found");
    return {}
  }

  const sessionSigner = privateKeyToAccount(sessionPrivateKey);

  /*
  const sessionSignerStored = JSON.parse(
    localStorage.getItem("ss_" + agwAddress)
  );
*/
  if (!sessionSigner) {
    console.log("Session Signer not found");
    return {}
  }
  /*
  console.log("retrieveSessionSignerAgw", {
    sessionSigner,
    sessionSignerStored,
  });
*/
  const exp = localStorage.getItem("sexp_" + agwAddress);
  let expires = 0;
  if (exp && exp !== "") {
    expires = parseInt(exp);
    const now = Math.floor(Date.now() / 1000);
    if (now > expires) {
      console.log("Session expired");
      return {};
    }
  } else {
    console.log("Session not found");
    return {};
  }

  const sessionConfig = generateSessionConfigAgw(sessionSigner, expires);
  return { sessionSigner, sessionConfig };
};

const sendUnsignTransactionByAgw = (
  unsignedTx,
  sendTransaction,
  wallets,
  agwAddress,
  agwClient,
  createSessionAsync,
  abi,
  functionName,
  args
) => {
  return new Promise(async (resolve, reject) => {

    console.log("[sender AGW] for", agwAddress);

    const embeddedWallet = wallets.find(
      (wallet) => wallet.walletClientType === "privy"
    );

    const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

    console.log("[sendUnsignTransactionByAgw]", unsignedTx);
    if (unsignedTx.from) unsignedTx.from = null;

    //探测AGW是否已经部署
    provider
      .getCode(agwAddress)
      .then(async (res) =>
      {
        //console.log("[code for agw]", res);

        if (res === "0x") {
          console.log("[AGW not deployed] for", agwAddress);
          //使用项目方GAS费托管来部署AGW
          unsignedTx.paymaster = isOnlineEnv()
            ? "0xd936d46956Ef4B8cc1B3a0474afa93E74ab503b6"
            : "0x5bCa693Ca2ADC0cCe5886b92f8300C904F4E4c36";
          unsignedTx.paymasterInput = getGeneralPaymasterInput({
            innerInput: "0x",
          });

          sendTransaction(unsignedTx)
            .then((resp) => resolve(resp))
            .catch((e) => {
              console.log("[AGW sendTransaction] e:" + e);
              if (
                e.message.indexOf("User already has an account") > 0 ||
                e.message.indexOf("(reading 'switchChain')") > 0
              ) {
                console.log("privy need login");
                reject("need login");
              } else {
                reject(e);
              }
            });
        } 
        else 
        {
          if (null == agwClient || null == createSessionAsync) {
            //没有AGW client，直接发送交易(提现等操作)
            console.trace("AGW sendTransaction bypass session", {client: agwClient, createSession: createSessionAsync});

            sendTransaction(unsignedTx)
              .then((resp) => resolve(resp))
              .catch((e) => {
                console.log("[AGW sendTransaction] e:" + e);
                if (
                  e.message.indexOf("User already has an account") > 0 ||
                  e.message.indexOf("(reading 'switchChain')") > 0
                ) {
                  console.log("privy need login");
                  reject("need login");
                } else {
                  reject(e);
                }
              });
          }
          else
          {
            //尝试使用session client发送交易
            console.log('[try retrieveSessionSignerAgw]');
            let { sessionSigner, sessionConfig } = retrieveSessionSignerAgw(
              agwAddress
            );  //尝试还原session
  
            if (!sessionSigner || !sessionConfig) {
              console.log("Session Signer not found. Create new session");
  
              sessionSigner = generateSessionSignerAgw(agwAddress);
              const exp = Math.floor(Date.now() / 1000) + 7 * 24 * 3600 // 7 * 24 hours
              localStorage.setItem('sexp_' + agwAddress, exp);
              sessionConfig = generateSessionConfigAgw(sessionSigner, exp);
  
              //尝试获取session
              try{
                const { session, transactionHash } = await createSessionAsync({
                  session: sessionConfig,
                });
              }catch(e){
                console.error("[AGW createSessionAsync] e:" + e);
                localStorage.setItem('sexp_' + agwAddress, 0);  //remove pending session
                throw e;
              }
            }
  
            const sessionClient = agwClient.toSessionClient(
              sessionSigner,
              sessionConfig
            );
            console.log(
              "Ready to Send With SessionClient. signer:",
              sessionSigner.address
            );
            //console.log(sessionClient);
  
            try {
              const hash = await sessionClient.writeContract({
                abi: abi_momentClub, //parseAbi([abi]),
                account: sessionClient.account,
                chain: abstract,
                address: unsignedTx.to,
                value: unsignedTx.value?.toBigInt(),
                functionName,
                args,
              });
              console.log('[AGW sessionClient.writeContract done] tx:', hash);
              resolve(hash);
            } catch (e) {
              console.log("[AGW sessionClient.writeContract] e:" + e);
              if (
                e.message.indexOf("User already has an account") > 0 ||
                e.message.indexOf("(reading 'switchChain')") > 0
              ) {
                console.log("privy need login");
                reject("need login");
              } else {
                reject(e);
              }
            }
          }
        }
      })
      .catch((e1) => {
        console.warn("[code expection]", e1.toString());

        sendTransaction(unsignedTx)
          .then((resp) => resolve(resp))
          .catch((e) => reject(e));
      });
    /*
        const crossAppAccount = user.linkedAccounts.find((account) => account.type === 'cross_app');
        console.log("[crossAppAccount]", crossAppAccount);
        const address = crossAppAccount.embeddedWallets[0].address;
        console.log("[cross_app embeddedWallets]", address);
*/
  });
};

const creatMomentClub = ({
  wallets,
  coinId,
  initBuyAmount_,
  callId,
  value,
  momentConf_,
  signature,
  validUntil,
  creationFee_,
  sendTransaction,
  agwAddress,
  agwClient,
  createSessionAsync,
}) => {
  // console.log(wallets);

  return new Promise(async (resolve, reject) => {
    try {
      const {
        contract,
        addr,
        gasConfig,
        contractInfo,
      } = await getmomentClubContract(wallets, coinId, true);
      //console.log(callId, initBuyAmount_, momentConf_, stringToHex(atob(signature)), gasConfig, `value:`, value, validUntil, creationFee_);
      const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
      );
      if (!contractInfo) {
        reject("get config error");
        return;
      }
      const coinInfo = momentChainToken.find((i) => i?.ID === coinId);
      const contractAddr = contractInfo?.momentFactoryContract;

      if (
        coinInfo?.isNative === 0 &&
        (coinInfo?.ID === LfgMainnetId || coinInfo?.ID === LfgTestnetId)
      ) {
        let currentAllowance = await getLfgAllowance(
          embeddedWallet,
          contractAddr,
          contractInfo?.coinContract
        );
        console.log("[getLfgAllowance]", {
          currentAllowance: ethers.utils.formatEther(currentAllowance),
          value,
        });
        if (ethers.utils.formatEther(currentAllowance) < value) {
          let amount = ethers.utils.parseEther(
            Number.MAX_SAFE_INTEGER.toString()
          );
          await lfgApprove(
            wallets,
            amount,
            contractInfo?.coinContract,
            coinInfo?.chainId,
            contractAddr
          ); //approve lfg for auction As MANY as Possible
        }
      }

      const cfg = {
        //...gasConfig,
        value: ethers.utils.parseEther(limitDecimals(value.toString(), 18)),
      };

      const signature_ = atob(signature);
      //console.log(cfg);
      console.log("newMomentClub", {
        callId,
        initBuyAmount_,
        creationFee: ethers.utils.formatEther(creationFee_),
        momentConf_,
        validUntil,
        sign: stringToHex(signature_),
      });

      contract.populateTransaction
        .newMomentClub(
          callId,
          initBuyAmount_,
          creationFee_,
          momentConf_,
          validUntil,
          stringToHex(signature_),
          cfg
        )
        .then((unsignedTx) => {
          const { abi, functionName, args } = {
            abi: "",
            functionName: "newMomentClub",
            args: [
              callId,
              initBuyAmount_,
              creationFee_,
              momentConf_,
              validUntil,
              stringToHex(signature_),
            ],
          };

          sendUnsignTransactionByAgw(
            unsignedTx,
            sendTransaction,
            wallets,
            agwAddress,
            agwClient,
            createSessionAsync,
            abi,
            functionName,
            args
          )
            .then((resp) => resolve(resp))
            .catch((e) => {
              console.log("[creatMomentClub exception]", e);
              if (
                e.message.indexOf("User already has an account") > 0 ||
                e.message.indexOf("(reading 'switchChain')") > 0
              ) {
                console.log("privy need login");
                reject("need login");
              } else {
                reject("Transaction Failed");
              }
            });
        })
        .catch((e) => {
          console.log("[creatMomentClub exception]", e);
          if (
            e.message.indexOf("User already has an account") > 0 ||
            e.message.indexOf("(reading 'switchChain')") > 0
          ) {
            console.log("privy need login");
            reject("need login");
          } else {
            reject("Transaction Failed");
          }
        });
    } catch (error) {
      console.log("[creatMomentClub exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

const getMomentEntropyFee = (wallets, coinId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { contract, addr, gasConfig } = await getmomentClubContract(
        wallets,
        coinId
      );
      // const contract = new ethers.Contract(contractAddr, erc721Abi, provider);

      contract
        .getEntropyFee()
        .then((res) => {
          console.log("[getEntropyFee] ", res);
          resolve(res);
        })
        .catch((e) => {
          console.log("[getEntropyFee exception]", e);
          if (
            e.message.indexOf("User already has an account") > 0 ||
            e.message.indexOf("(reading 'switchChain')") > 0
          ) {
            console.log("privy need login");
            reject("need login");
          } else {
            reject("Transaction Failed");
          }
        });
    } catch (error) {
      console.log("[getEntropyFee exception]", error);
      if (error.message.indexOf("(reading 'switchChain')") > 0) {
        console.log("privy need login");
        reject("need login");
      } else {
        reject("Transaction Failed");
      }
    }
  });
};

export {
  mintMomentToken,
  creatMomentClub,
  checkTransaction,
  getAssetTransfers,
  getNftMetadata,
  getMomentEntropyFee,
  sendUnsignTransactionByAgw,
  generateSessionSignerAgw,
  generateSessionConfigAgw,
};
