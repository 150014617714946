import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import {ethers} from "ethers";
import {abi_core_pool, abi_token} from "@/utils/abi";
import {getConfig} from "@/utils/common";
import {parseString} from "superagent/lib/client";
import {getGasConfig} from "@/utils/gas";


export function lfgApproveOld(wallets, amount) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lfgTokenAddr, corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const privyProvider = await embeddedWallet.getEthereumProvider();
            const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider).connect(signer);

            contract.populateTransaction.approve(corePoolAddr, ethers.utils.parseEther(amount.toString()), await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgApprove(wallets, amount, lfgTokenAddr, chainId, approveAddr) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider).connect(signer);

            contract.populateTransaction.approve(approveAddr, amount, await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => {
                        console.log(`[lfgApprove] for[${approveAddr}] value[${ethers.utils.formatEther(amount)}]`);
                        resolve(resp);
                    }
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStake(wallets, amount, ts) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider).connect(signer);

            contract.populateTransaction.stake(ethers.utils.parseEther(amount.toString()), ts, await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgUnStake(wallets, _depositId, amount) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider).connect(signer);

            contract.populateTransaction.unstake(_depositId, ethers.utils.parseEther(amount.toString()), await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeBalanceOf(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const {corePoolAddr, chainId} = await getConfig();
            if(!corePoolAddr || !chainId) return
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.balanceOf(signer.getAddress()).then(
                resp => resolve(ethers.utils.formatEther(resp))
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeGetDepositsLength(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.getDepositsLength(signer.getAddress()).then(
                resp => resolve(resp.toNumber())
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeGetDeposits(wallets, _depositId) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.getDeposit(signer.getAddress(), _depositId).then(
                resp => resolve({
                    DepositId: _depositId,
                    StakedAmount: ethers.utils.formatEther(resp.tokenAmount),
                    StakedUnlockTime: resp.lockedUntil.toNumber(),
                })
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeUsersLockingWeight(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.usersLockingWeight().then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeConfig(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.getConfig().then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}


export function lfgStakeGetCurrentValues(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.getCurrentValues().then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeBlockNumber(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.blockNumber().then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakeUsers(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.users(signer.getAddress()).then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgStakePendingYieldRewards(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider)

            contract.pendingYieldRewards(signer.getAddress()).then(
                resp => resolve(ethers.utils.formatEther(resp))
            ).catch(
                e => reject(e)
            )
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgProcessRewards(wallets) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {corePoolAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(corePoolAddr, abi_core_pool, provider).connect(signer);

            contract.populateTransaction.processRewards(await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgTransfer(wallets, toWallet, amount, chainId, lfgTokenAddr) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            //const {lfgTokenAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider).connect(signer);

            contract.populateTransaction.transfer(toWallet, ethers.utils.parseEther(amount.toString()), await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('User already has an account') > 0 || e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

