import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import CustomIcon from "@/common/CustomIcon";
import AntButton from "@/common/AntButton";

const PcPWAIntroNew = ({
    closeAInstall,
}) => {
    const {t, i18n} = useTranslation();
    const [leftSec,setLeftSec] = useState(5)
    useEffect(()=>{
        let timer = null
        if(!timer){
            timer = setInterval(()=>{
                setLeftSec(leftSec=>{
                    const _leftSec = --leftSec
                    if(_leftSec === 0){
                        clearInterval(timer)
                        closeAInstall()
                    }
                    return leftSec--
                })
            },1000)
        }
        return ()=>{
            timer && clearInterval(timer)
        }
    },[])
    return (
        <>
            <div className={`${s.momentWrap} flex_center_start_col`}>
                <div className={`${s.title} flex_center_center`}>
                    {t('Install Now!')}
                </div>
                <div className={`${s.imageRoot} flex_center_center`}>
                    <img className={`${s.image}`} src="/UI/Picture/quicksetup.png"></img>
                </div>
                <div className={`${s.tips}`}>
                    {t('Install TurnUp on Your Device to Play')}
                </div>
                <div className={`${s.commonBtn} flex_center_center`} onClick={closeAInstall}>
                    <div className={`fs30 fb color-white`}>
                        {`${t('Install')}(${leftSec})`}
                    </div>
                </div>
                {/*<CustomIcon 
                    className={`momentCloseBtn`} 
                    width={40} 
                    height={40} 
                    imgName={`Picture/UI_Picture_Icon_Close_02`}
                    onClick={() => {
                        closeAInstall();
                    }} />*/}
            </div>
        </>
    )
}
export default memo(PcPWAIntroNew);
