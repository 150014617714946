// import WebIM from "@/utils/WebIM";
// import {CHAT_TYPE} from "@/utils/common";
let deWeightThree = (arr, name) => {
    // let name = 'name';
    let map = new Map();
    for (let item of arr) {
        if (!map.has(item[name])) {
            map.set(item[name], item);
        }
    }
    return [...map.values()];
}
const App = {
    state: {
        hasLogined: false, //是否已登录
        hasInvite: false, //是否已验证邀请码
        hasLink: false, //是否已关联tiktok
        hasBalance: false, //是否已完成首充
        online: false,
        selChatType: ['txt'],
        hideRefreshBtn: false, //是否隐藏刷新列表按钮
        userInfo: JSON.parse(localStorage.getItem('_TT_userInfo')) || {},
        inviteList: [],
        defaultInviteCode: "",
        earnInfo: {},
        exportActiveTab: 0,
        web3Config: null,
        tranList: {
            self: {},
            friends: {},
            global: {},
        },
        rankList: {
            1: {},
            2: {},
            3: {},
            home: {},
        },
        missionType: '1',
        filterBidNftSingle: {
            rankRange: [1, 2000],
            maticPriceRange: ["0", "-1"],
            lfgPriceRange: ["0", "-1"],
            isAsc: true,
            includeTopPriceIsSelf: true
        },
        filterBidNftSweep: {
            rankRange: [1, 2000],
            maticPriceRange: ["0", "-1"],
            lfgPriceRange: ["0", "-1"],
            isAsc: true,
            includeTopPriceIsSelf: false
        },
        showCharContent: false,
        showSettings: false,
        initTopUpAmount: JSON.parse(localStorage.getItem('_TT_initTopUpAmount')) || 0,
        showOtherTransContent: false,
        showBalance: JSON.parse(localStorage.getItem('_TT_showBalance')) || false, //显示充值页面
        showLink: false,  //显示绑定界面
        curentOtherUserInfo: {},
        lastGetAuctionInfoTimestamp: 0,
        currentAuctionBatchCfg: {},
        currentAuctionBatchInfo: {},
        searchUserList: [],
        bidSlippage: 0,
        lootTargetUserInfo: null,
        lastLootTargetUserInfo: null,
        lootEmployeeUserInfo: null,
        lastLootEmployeeUserInfo: null,
        lootSearchClubLvRange: null,
        costLfgType: localStorage.getItem('_TT_costLfgType') || 1,//1:lfg pool , 0:lfg wallet
        showClubCardDetailInfo: localStorage.getItem('_TT_showClubCardDetailInfo') || false,
        gasMode: localStorage.getItem('_TT_gasMode') || 0,//0:suggested, 1:low
        allClubCardType: 0,//0:players, 1:nfts
        myClubChooseType: 0,//0:Players, 1:activities, 2:nfts
        walletBalanceList: [],
        memeDntBalanceList: {},
        momentDntBalanceList: {},
        lastUpdateMemeDntBalanceListTime: -60000,//ms
        lastUpdateMomentDntBalanceListTime: -60000,//ms
        memeClubFilterList: null,
        momentClubFilterList: null,
        tgeMemeClubFilterList: null,
        memeUnpackInfo: null,
        memeAssetParam: null,
        momentAssetParam: null,
        memeAssetUnpackList: null,
        momentAssetUnpackList: null,
        momentOtherUserDetailType: 1,
        newbeeSwipeVideoCount: Number(localStorage.getItem('_TT_swipeVideoCount')) || 0,
        swipeVideoTaskProgressList: JSON.parse(localStorage.getItem('_TT_swipeVideoTaskProgressList')) || {},
        platformUpdateEndTime: 0,
        forceBindTiktok: false,
        choosedTiktokVideoInfo: null,

        memeAssetUnpackListQueryLastTime: -120000,
        momentAssetUnpackListQueryLastTime: -120000,
        memeUnpackCount: 0,
        momentUnpackCount: 0,
        hideNSFW: !(localStorage.getItem('_TT_showNSFW') || false),
        lastVideoSwitch: -1500,
        switchVideoType: localStorage.getItem('_TT_switchVideoType') || 0,//-1:single, 0:empty, 1:clublist, 2:tge, 3:my assets-unclaimed, 4:my assets-create, 5:my assets-favourite
        switchVideoList: null,
        switchVideoListCursor: "",
        memeclubSwitchVideoParams: null,
        globalVideoMute: false,
        videoPreloadString: '',
        currentMomentClubId: localStorage.getItem('_TT_currentMomentClubId') || 0,
        curRtcInfo: {
            userId: '',
            keyId: '',
            role: '',
            channelName: '',
            heat: 0,
            onlineNum: 0,
            remoteUsers: [],
            videoTrack: null,
            audioTrack: null,
            resolution: {
                width: 0,
                height: 0
            }
        },
        chatMap: {
            groupChat: new Map(),
        },
        groupInfo: JSON.parse(localStorage.getItem('_TT_groupInfo')) || {},
        customChatMap: {
            groupChat: new Map(),
        },
        webIMLogined: false,
        accessToken: JSON.parse(localStorage.getItem('_TT_accessToken')) || '',
        webIMUserInfo: JSON.parse(localStorage.getItem('_TT_webIMUserInfo')) || {},
        liveMessage: [],
        rankHome: {}, //底部标签下的Rank排行榜
        showGasMode: false,
    },
    reducers: {
        /**
         * @description 更新state
         * @param state: 当前state
         * @param payload: 更新state对象
         */
        updateState(state, payload) {
            return {
                ...state,
                ...payload
            };
        },
        updateUserInfo(state, userInfo) {
            window.localStorage.setItem('_TT_userInfo', JSON.stringify({
                ...state.userInfo,
                ...userInfo
            }))
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...userInfo
                }
            }
        },
        updateEarnInfo(state, earnInfo) {
            return {
                ...state,
                earnInfo: {
                    ...state.earnInfo,
                    ...earnInfo
                }
            }
        },
        updateTranList(state, {type, data}) {
            return {
                ...state,
                tranList: {
                    ...state.tranList,
                    [type]: data
                }
            }
        },
        updateTranNowType(state, type) {
            return {
                ...state,
                tranList: {
                    ...state.tranList,
                    nowType: type,
                },
            }
        },
        updateTranNowSubType(state, type) {
            return {
                ...state,
                tranList: {
                    ...state.tranList,
                    nowSubType: type,
                },
            }
        },
        updateRankHome(state, data) {
            return {
                ...state,
                rankHome: {
                    ...state.rankHome,
                    ...data
                }
            }
        },
        updateRankList(state, {type, data}) {
            return {
                ...state,
                rankList: {
                    ...state.rankList,
                    [type]: data
                }
            }
        },
        updateRankNowType(state, type) {
            return {
                ...state,
                rankList: {
                    ...state.rankList,
                    nowType: type,
                },
            }
        },
        updateCurRtcInfo(state, data) {
            return {
                ...state,
                curRtcInfo: {
                    ...state.curRtcInfo,
                    ...data
                }
            }
        },
        // 存储userId对应groupId
        updateGroupInfo(state, data) {
            window.localStorage.setItem('_TT_groupInfo', JSON.stringify({
                ...state.groupInfo,
                ...data
            }))
            return {
                ...state,
                groupInfo: {
                    ...state.groupInfo,
                    ...data
                }
            }
        },
        // 拉取历史消息插数据
        pushMessage(state, {chatType, fromId, messageInfo, reset}) {
            let ls = [];
            if (!reset && state.chatMap[chatType].has(fromId)) {
                ls = [
                    ...state.chatMap[chatType].get(fromId)?.list,
                    ...messageInfo?.list
                ];
            } else {
                ls = messageInfo?.list;
            }
            ls = deWeightThree(ls, 'id')
            return {
                ...state,
                chatMap: {
                    ...state.chatMap,
                    [chatType]: new Map([
                        ...state.chatMap[chatType],
                        ...new Map().set(fromId, {
                            ...messageInfo,
                            list: ls
                        })
                    ])
                }
            };
        },
        // 发送消息收到消息头部插入数据
        insertMessage(state, {chatType, fromId, messageInfo}) {
            let ls = [];
            const dt = state.chatMap[chatType].get(fromId);
            if (state.chatMap[chatType].has(fromId) && dt?.list) {
                ls = [...messageInfo?.list, ...dt?.list];
            } else {
                ls = messageInfo?.list;
            }
            ls = deWeightThree(ls, 'id')
            return {
                ...state,
                chatMap: {
                    ...state.chatMap,
                    [chatType]: new Map([
                        ...state.chatMap[chatType],
                        ...new Map().set(fromId, {
                            ...dt,
                            ...messageInfo,
                            list: ls
                        })
                    ])
                }
            };
        },
        // 更新直播弹幕消息
        updateLiveMessage(state, {messageInfo, reset}) {
            let ls = [];
            if (!reset) {
                ls = [
                    ...messageInfo,
                    ...state.liveMessage,
                ]
            } else {
                ls = messageInfo
            }
            return {
                ...state,
                liveMessage: ls
            }
        },
        // 插入自定义消息
        insertCustomMessage(state, {chatType, fromId, messageInfo}) {
            let ls = [];
            const dt = state.customChatMap[chatType].get(fromId);
            if (state.customChatMap[chatType].has(fromId) && dt?.list) {
                ls = [...messageInfo?.list, ...dt?.list];
            } else {
                ls = messageInfo?.list;
            }
            return {
                ...state,
                customChatMap: {
                    ...state.customChatMap,
                    [chatType]: new Map([
                        ...state.customChatMap[chatType],
                        ...new Map().set(fromId, {
                            ...dt,
                            ...messageInfo,
                            list: ls
                        })
                    ])
                }
            };
        },
        // 删除自定义消息
        deleteCustomMessage(state, {chatType, fromId, id}) {
            const dt = state.customChatMap[chatType].get(fromId);
            if (state.customChatMap[chatType].has(fromId)) {
                let ls = [...state.customChatMap[chatType].get(fromId)?.list];
                const idx = ls.findIndex((item) => {
                    return item.id === id;
                });
                if (idx > -1) {
                    ls.splice(idx, 1);
                    return {
                        ...state,
                        customChatMap: {
                            ...state.customChatMap,
                            [chatType]: new Map([
                                ...state.customChatMap[chatType],
                                ...new Map().set(fromId, {
                                    ...dt,
                                    list: ls
                                })
                            ])
                        }
                    };
                }
            }
            return state;
        },
        //本地发送的消息状态更新，发送成功时消息id修改为serverId
        updateChatMessageIdMid(state, {id, mid, to, status, chatType}) {
            // if (to === state.currentChatInfo.id) {
            // const chatType = state.currentChatInfo.chatType
            const dt = state.chatMap[chatType].get(to)
            if (state.chatMap[chatType].has(to)) {
                const ls = dt?.list || [];
                const findIndex = ls.findIndex(item => item.id === id);
                if (findIndex > -1) {
                    const newMsg = {...ls[findIndex], ...{status, id: mid || id}}
                    ls.splice(findIndex, 1, newMsg);
                    return {
                        ...state,
                        chatMap: {
                            ...state.chatMap,
                            [chatType]: new Map([
                                ...state.chatMap[chatType],
                                ...new Map().set(to, {
                                    ...dt,
                                    list: ls
                                })
                            ])
                        }
                    }
                }
            }
            // }
            // return state
        },

    },
    effects: () => ({
        setHasLogined(hasLogined) {
            this.updateState({hasLogined});
        },
        setOnline(online) {
            this.updateState({online});
        },
        setHasInvite(hasInvite) {
            this.updateState({hasInvite});
        },
        setHasLink(hasLink) {
            this.updateState({hasLink});
        },
        setHasBalance(hasBalance) {
            this.updateState({hasBalance});
        },
        setUserInfo(userInfo) {
            this.updateUserInfo(userInfo);
        },
        setInviteList(inviteList) {
            this.updateState({inviteList});
        },
        setShowCharContent(showCharContent) {
            this.updateState({showCharContent});
        },
        setShowOtherTransContent(showOtherTransContent) {
            this.updateState({showOtherTransContent});
        },
        setCurentOtherUserInfo(curentOtherUserInfo) {
            this.updateState({curentOtherUserInfo});
        },
        setTranList({type, data}) {
            this.updateTranList({type, data})
        },
        setTranNowType(type) {
            this.updateTranNowType(type)
        },
        setTranNowSubType(type) {
            this.updateTranNowSubType(type)
        },
        setRankList({type, data}) {
            this.updateRankList({type, data})
        },
        setRankHome({type, data}) {
            this.updateRankHome({type, data})
        },
        setRankNowType(type) {
            this.updateRankNowType(type)
        },
        setSearchUserList(searchUserList) {
            this.updateState({searchUserList});
        },
        setShowSettings(showSettings) {
            this.updateState({showSettings});
        },
        setCurRtcInfo(data) {
            this.updateCurRtcInfo(data)
        },
        setGroupInfo(data) {
            this.updateGroupInfo(data)
        },
        pushChatMessage({fromId, messageInfo, chatType, reset}) {
            this.pushMessage({fromId, messageInfo, chatType, reset});
        },
        insertChatMessage({fromId, messageInfo, chatType, reset}, rootState) {
            this.insertMessage({fromId, messageInfo, chatType, reset});
        },
        insertChatCustomMessage({fromId, messageInfo, chatType, reset}, rootState) {
            this.insertCustomMessage({fromId, messageInfo, chatType, reset});
        },
        deleteChatCustomMessage({chatType, fromId, id}) {
            this.deleteCustomMessage({chatType, fromId, id});
        },
        setLiveMessage({messageInfo, reset}) {
            this.updateLiveMessage({messageInfo, reset});
        },
        setWebIMLogined(webIMLogined) {
            this.updateState({webIMLogined})
        },
        setAccessToken(accessToken) {
            this.updateState({accessToken})
        },
        setWebIMUserInfo(webIMUserInfo) {
            this.updateState({webIMUserInfo})
        },
        setHideRefreshBtn(hideRefreshBtn) {
            this.updateState({hideRefreshBtn})
        },
        updateChatMessageId({id, mid, to, status, chatType}) {
            this.updateChatMessageIdMid({id, mid, to, status, chatType})
        },
        setShowBalance(showBalance) {
            this.updateState({showBalance})
        },
        setShowLink(showLink) {
            this.updateState({showLink})
        },
        setInitTopUpAmount(initTopUpAmount) {
            this.updateState({initTopUpAmount})
        },
        setDefaultInviteCode(defaultInviteCode) {
            this.updateState({defaultInviteCode})
        },
        setEarnInfo(earnInfo) {
            this.updateEarnInfo(earnInfo)
        },
        setSelChatType(selChatType) {
            this.updateState({selChatType})
        },
        setExportActiveTab(exportActiveTab) {
            this.updateState({exportActiveTab})
        },
        setCurrentAuctionBatchCfg(currentAuctionBatchCfg) {
            this.updateState({currentAuctionBatchCfg})
        },
        setCurrentAuctionBatchInfo(currentAuctionBatchInfo) {
            this.updateState({currentAuctionBatchInfo})
        },
        setLastGetAuctionInfoTimestamp(lastGetAuctionInfoTimestamp) {
            this.updateState({lastGetAuctionInfoTimestamp})
        },
        setBidSlippage(bidSlippage) {
            this.updateState({bidSlippage})
        },
        setFilterBidNftSingle(filterBidNftSingle) {
            this.updateState({
                filterBidNftSingle
            })
        },
        setFilterBidNftSweep(filterBidNftSweep) {
            this.updateState({
                filterBidNftSweep
            })
        },
        setLootTargetUserInfo(lootTargetUserInfo) {
            this.updateState({lootTargetUserInfo})
        },
        setLootEmployeeUserInfo(lootEmployeeUserInfo) {
            this.updateState({lootEmployeeUserInfo})
        },
        setLootSearchClubLvRange(lootSearchClubLvRange) {
            this.updateState({lootSearchClubLvRange})
        },
        setCostLfgType(costLfgType) {
            this.updateState({costLfgType})
        },
        setLastLootTargetUserInfo(lastLootTargetUserInfo) {
            this.updateState({lastLootTargetUserInfo})
        },
        setLastLootEmployeeUserInfo(lastLootEmployeeUserInfo) {
            this.updateState({lastLootEmployeeUserInfo})
        },
        setShowClubCardDetailInfo(showClubCardDetailInfo) {
            this.updateState({showClubCardDetailInfo})
        },
        setMissionType(missionType) {
            this.updateState({missionType})
        },
        setShowGasMode(showGasMode) {
            this.updateState({showGasMode})
        },
        setAllClubCardType(allClubCardType) {
            this.updateState({allClubCardType})
        },
        setMyClubChooseType(myClubChooseType) {
            this.updateState({myClubChooseType})
        },
        setWalletBalanceList(walletBalanceList) {
            this.updateState({walletBalanceList})
        },
        setMemeDntBalanceList(memeDntBalanceList){
            this.updateState({memeDntBalanceList})
        },
        setMomentDntBalanceList(momentDntBalanceList){
            this.updateState({momentDntBalanceList})
        },
        
        setLastUpdateMemeDntBalanceListTime(lastUpdateMemeDntBalanceListTime){
            this.updateState({lastUpdateMemeDntBalanceListTime})
        },
        setLastUpdateMemeDntBalanceListTime(lastUpdateMomentDntBalanceListTime){
            this.updateState({lastUpdateMomentDntBalanceListTime})
        },
        
        setMemeClubFilterList(memeClubFilterList){
            this.updateState({memeClubFilterList})
        },
        setMomentClubFilterList(momentClubFilterList){
            this.updateState({momentClubFilterList})
        },
        setTgeMemeClubFilterList(tgeMemeClubFilterList){
            this.updateState({tgeMemeClubFilterList})
        },
        setTgeMomentClubFilterList(tgeMomentClubFilterList){
            this.updateState({tgeMomentClubFilterList})
        },
        setMemeUnpackInfo(memeUnpackInfo){
            this.updateState({memeUnpackInfo})
        },
        setMemeUnpackInfo(momentUnpackInfo){
            this.updateState({momentUnpackInfo})
        },
        setMemeAssetParam(memeAssetParam){
            this.updateState({memeAssetParam})
        },
        setMomentAssetParam(momentAssetParam){
            this.updateState({momentAssetParam})
        },
        setMemeAssetUnpackList(memeAssetUnpackList){
            this.updateState({memeAssetUnpackList})
        },
        setMomentAssetUnpackList(momentAssetUnpackList){
            this.updateState({momentAssetUnpackList})
        },
        setMemeAssetUnpackListQueryLastTime(memeAssetUnpackListQueryLastTime){
            this.updateState({memeAssetUnpackListQueryLastTime})
        },
        setMomentAssetUnpackListQueryLastTime(momentAssetUnpackListQueryLastTime){
            this.updateState({momentAssetUnpackListQueryLastTime})
        },
        setWeb3Config(web3Config){
            this.updateState({web3Config})
        },
        setMemeUnpackCount(memeUnpackCount){
            this.updateState({memeUnpackCount})
        },
        setHideNSFW(hideNSFW){
            this.updateState({hideNSFW})
        },
        setLastVideoSwitch(lastVideoSwitch){
            this.updateState({lastVideoSwitch})
        },
        setSwitchVideoType(switchVideoType){
            localStorage.setItem('_TT_switchVideoType', switchVideoType)
            this.updateState({switchVideoType})
        },
        setSwitchVideoList(switchVideoList){
            this.updateState({switchVideoList})
        },
        setSwitchVideoListCursor(switchVideoListCursor){
            this.updateState({switchVideoListCursor})
        },
        setMemeclubSwitchVideoParams(memeclubSwitchVideoParams){
            this.updateState({memeclubSwitchVideoParams})
        },
        setGlobalVideoMute(globalVideoMute){
            this.updateState({globalVideoMute})
        },
        setVideoPreloadString(videoPreloadString){
            this.updateState({videoPreloadString})
        },
        setCurrentMomentClubId(currentMomentClubId){
            localStorage.setItem('_TT_currentMomentClubId', currentMomentClubId)
            this.updateState({currentMomentClubId})
        },
        setMomentOtherUserDetailType(momentOtherUserDetailType){
            this.updateState({momentOtherUserDetailType})
        },
        setNewbeeSwipeVideoCount(newbeeSwipeVideoCount){
            localStorage.setItem('_TT_swipeVideoCount', newbeeSwipeVideoCount)
            this.updateState({newbeeSwipeVideoCount})
        },
        setSwipeVideoTaskProgressList(swipeVideoTaskProgressList){
            localStorage.setItem('_TT_swipeVideoTaskProgressList', JSON.stringify(swipeVideoTaskProgressList))
            this.updateState({swipeVideoTaskProgressList})
        },
        setPlatformUpdateEndTime(platformUpdateEndTime){
            this.updateState({platformUpdateEndTime})
        },
        setForceBindTiktok(forceBindTiktok){
            this.updateState({forceBindTiktok})
        },
        setChoosedTiktokVideoInfo(choosedTiktokVideoInfo){
            this.updateState({choosedTiktokVideoInfo})
        },
    })
};

export default App;
