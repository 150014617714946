import React, { useMemo, useState, memo, useRef } from "react";
import { connect } from "react-redux";
import s from "./index.module.less";
import HomeTips from "src/common/HomeTips";
import { 
    loginFn, 
    borwserOS, 
    borwserPlatform, 
    unit, 
    getConfig, 
    taPush,
    getNextVideoTask,
    isOneShotTasksContain,
} from "@/utils/common";

import { closeAddScreen, envConfig } from "@/utils/env";

import { StoreContext } from "@/contexts/StoreContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Button, message } from "antd";

import { usePrivy, useWallets } from "@privy-io/react-auth";

import { abstract } from "viem/chains";
import { useAbstractPrivyLogin } from "@abstract-foundation/agw-react/privy";
import { useAbstractClient } from "@abstract-foundation/agw-react";

import { useCreateSession } from "@abstract-foundation/agw-react";
import { generatePrivateKey, privateKeyToAccount } from "viem/accounts";
import { LimitType } from "@abstract-foundation/agw-client/sessions";
import { toFunctionSelector, parseEther } from "viem";

//import { useWriteContractSponsored } from "@abstract-foundation/agw-react";
//import { getSmartAccountAddressFromInitialSigner } from "@abstract-foundation/agw-client";

import { useAccount, useBalance } from "wagmi";
import { useBlockNumber } from "wagmi";

//import Banner from "src/common/Banner";
import MobileBG from "@/assets/video/HomepageVideo.mp4";
import SiteBG from "@/assets/video/Homepage_Web.mp4";
//import SiteBGFrame from "@/assets/images/bgImg/Homepage_WebFrame1.webp";
//import MobileBGFrame from "@/assets/images/bgImg/HomepageVideoFrame1.webp";

import CustomIcon from "src/common/CustomIcon";
import { useTranslation, Trans } from 'react-i18next';
import { 
    mainHomePath,
    gameMomentClubDetailPath,
    gameMomentClubExplorePath,
    gameMomentClubPath,
} from "@/routes/config";

import ButtonFactory from "@/common/ButtonFactory";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AntModal from "@/common/AntModal";
import txtImg from "@/assets/images/bgImg/UI_Picture-Web_Word_01.png"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { 
    defaultMemeSwitchVideoParams, 
    momentClubList,
} from "@/utils/momentClub";
import PcPWAIntroNew from "@/model/PcPWAIntroNew";

// import LoginSwiper from "@/common/LoginSwiper";
const locationUserInfo = JSON.parse(localStorage.getItem('_TT_userInfo'));

const LoginNew = (
    {
        userInfo,
        setUserInfo,
        setWebIMUserInfo,
        setShowBalance,
        token,
        setInitTopUpAmount,
        hideNSFW,
        setCurrentMomentClubId,
        setSwitchVideoList,
        setSwitchVideoListCursor,
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { ready, user, logout, getAccessToken } = usePrivy();
    const { wallets } = useWallets();

    //Abstract Privy Hooks
    const { login,  link } = useAbstractPrivyLogin();
    const { address: agwAddress, chainId: agwChainId, status: agwStatus } = useAccount();
    const { data: abstractClient, isLoading: abstractClientIsLoading, error:abstractClientError } = useAbstractClient();

    const asbBlockNumber = useBlockNumber(
        {
            enabled: true,
            chainId: abstract.chainId,
        }
    );

    const { data : absBalanceData, isError : absBalanceIsError, isLoading : absBalanceIsLoading } = useBalance({
        address: agwAddress,
        chainId: agwChainId,
        enabled: !!agwAddress, // Only run the hook if walletAddress is available
    });
    //const { sendTransaction, isPending } = useSendTransaction();

    const { t, i18n } = useTranslation();
    const { db } = React.useContext(StoreContext);
    const userInfoObj = JSON.stringify(userInfo) !== '{}' ? userInfo?.selfData : db.get('userInfo') || locationUserInfo;
    // useEffect(()=>{
    //     setUserInfo(userInfoObj)
    // },[userInfoObj])

    // const [openDialog, setOpenDialog] = useState(false);
    const [_hasLogined, _setHasLogined] = useState(userInfoObj?.userId ? true : false);
    const [_hasInvite, _setHasInvite] = useState(userInfoObj?.inviteCodeCheck);
    const [_hasLink, _setHasLink] = useState(userInfoObj?.platform?.uid);
    const [_payEntryFee, _setPayEntryFee] = useState(userInfoObj?.payEntryFee);
    const [_buyFirstKey, _setBuyFirstKey] = useState(userInfoObj?.buyFirstKey);
    const [_balance, _setBalance] = useState(userInfoObj?.balance);
    const [loading, setLoading] = useState(false);
    const [loadingAfterPrivy, setLoadingAfterPrivy] = useState(false);
    const [updateLoadingCount, setUpdateLoadingCount] = useState(0);
    const [authToken, setAuthToken] = useState('');
    const [swiper, setSwiper] = useState(null);
    const [showTips,setShowTips] = useState(false)
    const [loadingAfterPrivyLaterCount, setLoadingAfterPrivyLaterCount] = useState(0);
    const loadingAfterPrivyTimestampRef = useRef(0);

    const [showInstallTips,setShowInstallTips] = useState(false)

    const closeAInstall = ()=>{
        setShowInstallTips(false)
        installApp()
    }

    const walletReady = useMemo(() => {
        return ready && wallets.length
    }, [ready, user, wallets])

    const { userId, selfData } = useMemo(() => {
        return userInfo || {}
    }, [userInfo])

    const installApp = async () => {
        /*
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            return;
        }
        promptEvent.prompt();

        const result = await promptEvent.userChoice;
        window.deferredPrompt = null;*/

        window.open('/?pswutlzoq=install', '_self');
    }

    const goMomentClub = () => {
        let params = defaultMemeSwitchVideoParams;
        loadingAfterPrivyTimestampRef.current = (new Date().getTime()) + 500;
        setLoadingAfterPrivyLaterCount(loadingAfterPrivyLaterCount + 1);
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: ""
        }).then(res => {
            loadingAfterPrivyTimestampRef.current = 0;
            let _list = []; 
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }

            let startIndex = -1;
            let nextVideoTask = getNextVideoTask(userInfo?.selfData?.task);
            if (nextVideoTask && 
                nextVideoTask?.numOfvideo === 0 && 
                !isOneShotTasksContain(userInfo?.selfData?.task?.oneShotTasks, nextVideoTask?.taskID) &&
                nextVideoTask?.videoUrl){
                const tempTaskVideo = {
                    clubId: nextVideoTask?.taskID * -1,
                    momentAuditCode: 1,
                    videoId: nextVideoTask?.videoId,
                    videoUrl: nextVideoTask?.videoUrl,
                    coverUrl: nextVideoTask?.videoFirstFrameUrl,
                    videoOriHeight: nextVideoTask?.videoOriHeight,
                    videoOriWidth: nextVideoTask?.videoOriWidth,
                    videoFirstFrameUrl: nextVideoTask?.videoFirstFrameUrl,
                    isTaskVideo: true,
                }
                _list.unshift(tempTaskVideo);
                startIndex = 0;
            }
            else{
                if (_list?.length > 0){
                    for (let i = 0; i < _list?.length; i++){
                        if (_list[i]?.momentAuditCode !== 3){
                            startIndex = i;
                            break;
                        }
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
            if (startIndex >= 0){
                setCurrentMomentClubId(_list[startIndex].clubId);
                navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
            }
            else{
                navigate(gameMomentClubExplorePath);
            }
        }).catch(e => {
            console.error(e);
            loadingAfterPrivyTimestampRef.current = 0;
            navigate(gameMomentClubExplorePath);
        })
    }

    const loginSuccessNavigate = () => {

        const isInMomentGame = location.pathname.startsWith(gameMomentClubPath);
        if (window.isMomentsLink || isInMomentGame ){
            if( selfData?.bindReferralCode){
                goMomentClub();
            }
        }
        else{
            navigate(mainHomePath, { replace: true });
        }
    }

    useEffect(() => {
        console.log(`[walletReady] walletReady: ${walletReady} _hasLogined: ${_hasLogined}`);
        if (walletReady && !authToken && !_hasLogined) {
            getAccessToken().then(res => {
                setAuthToken(res);
                //console.error('Privy Login Success setAuthToken', res);
                sign(res)
            }).catch(e => {
                console.error('Privy Login Failed', e);
            });
        }
        if (_hasLogined) {
            loginSuccessNavigate();
        }
    }, [walletReady, _hasLogined])

    useEffect(() => {
        if (absBalanceData) {
            console.log(`[absBalanceData] absBalanceData: ${absBalanceData?.symbol} ${absBalanceData?.formatted}`);
        }
    }, [absBalanceData]);

    useEffect(() => {
        if (walletReady)
        {
            console.log(`[Abstract statues] chainID: ${agwChainId}, agwAddress: ${agwAddress}, status:${agwStatus}, blockNumber: ${asbBlockNumber.data}`);
            console.log("[Privy user]", user);
            if (null != user?.linkedAccounts) {
                const crossAppAccount = user.linkedAccounts.find((account) => account.type === 'cross_app');
                console.log("[crossAppAccount]", crossAppAccount);
                const address = crossAppAccount.embeddedWallets[0].address;
                console.log("[cross_app embeddedWallets]", address);
            }
        }
    }, [agwStatus, walletReady])


    useEffect(() => {
        if (walletReady)
        {
            console.log(`[abstractClient statues] abstractClient: ${abstractClient}, abstractClientIsLoading: ${abstractClientIsLoading}, abstractClientError:${abstractClientError}`);
        }
    }, [abstractClient, abstractClientIsLoading, abstractClientError])


    const isTopApp = useMemo(() => {
        if (window.navigator && (window.matchMedia("(display-mode: standalone)").matches || !0 === window.navigator.standalone || window.navigator.userAgent.includes("standalone"))) {
            return true
        }
        if (JSON.stringify(userInfoObj) !== '{}' && JSON.stringify(userInfoObj) !== 'null' && _hasInvite && (!_hasLink || (!_payEntryFee || !_buyFirstKey))) {
            return true
        }
        return closeAddScreen
    }, [])

    // const openSignD = () => {
    //     !loading && setOpenDialog(true)
    // }

    const loginSuccessHandle = (res) => {
        // setOpenDialog(false)
        // setLoading(false)
        getConfig()
        const token = res?.data?.token;
        const userInfo = res?.data?.user;
        const initTopUpAmount = res?.data?.initTopUpAmount;
        const { inviteCodeCheck, payEntryFee, buyFirstKey, balance, userId, platform } = userInfo;
        window?.progressier?.add({
            id: userId.toString()
        })
        if (envConfig.taEnable) {
            window.ta.login(envConfig.taRegionId + "-" + userId.toString())
        }
        // const hasInvite = inviteCodeCheck;
        // const hasLink = platform?.uid ? true : false;
        setShowBalance(!(buyFirstKey && payEntryFee))
        db.update({
            userInfo: {
                ...userInfo,
                selfData: {
                    ...userInfo
                }
            },
            token: {
                token
            },
            initTopUpAmount,
            showBalance: !(buyFirstKey && payEntryFee),
            webIMUserInfo: {
                username: userId.toString()
            }
        });
        setUserInfo({
            ...userInfo,
            selfData: {
                ...userInfo
            }
        })
        // setInitTopUpAmount(initTopUpAmount)
        // _setHasLogined(true)
        // _setHasInvite(hasInvite)
        // _setHasLink(hasLink)
        // _setPayEntryFee(payEntryFee)
        // _setBuyFirstKey(buyFirstKey)
        // setInitTopUpAmount(initTopUpAmount)
        setWebIMUserInfo({
            username: userId.toString()
        })
        db.set()
        // window.opener = null;
        // window.open("","_self");
        // window.close();
    }

    // const closeDialog = () => {
    //     setOpenDialog(false)
    // }

    //login with authToken in Web2 Server
    const sign = (_authToken) => {
        loginFn(_authToken, 4).then(res => {
            loginSuccessHandle(res)
        }).catch(e => {
            setLoading(false)
            console.error('Login Failed', e);
            logout()
            message.info({ content: "Login Failed, Please Check Your Network" })

        })
    }
    useEffect(() => {
        if (userId && walletReady) {
            loginSuccessNavigate();
        }
    }, [userId, walletReady])

    const loginPrivy = async () => {
        if (userId && walletReady) {
            loginSuccessNavigate();
            return
        }
        // await logout()
        login().then().catch(e => {
            console.error('Privy Login Failed', e);
            logout()
        })
    }


    const iOSNotSafari = useMemo(() => {
        const os = borwserOS();
        const platform = borwserPlatform()
        if (os === 'iOS' && platform !== 'safari') {
            return true
        } else {
            return false
        }

    }, [])

    const [skip, setSkip] = useState(false)

    const isIos = useMemo(() => {
        const os = borwserOS();
        if (os === 'iOS') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isAndroid = useMemo(() => {
        const os = borwserOS();
        if (os === 'android') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isPc = useMemo(() => {
        return !isAndroid && !isIos && !isTopApp
    },[isAndroid,isIos,isTopApp])

    const startFn = () => {
        if (isPc){
            setShowInstallTips(true);
        }
        else{
            if ((!isTopApp || iOSNotSafari) && !skip){
                installApp();
            }
            else{
                taPush('click_start_button');
                loginPrivy();
            }
        }
    }

    useEffect(() => {
        if (loadingAfterPrivy){
            setTimeout(() => {
                setUpdateLoadingCount(updateLoadingCount+1);
            }, 1000);
        }
    }, [loadingAfterPrivy, updateLoadingCount])

    useEffect(() => {
        if (loadingAfterPrivyTimestampRef.current > 0){
            let nowtime = new Date().getTime();
            if (loadingAfterPrivyTimestampRef.current <= nowtime){
                setLoadingAfterPrivy(true);
                loadingAfterPrivyTimestampRef.current = 0;
            }
            else{
                setTimeout(() => {
                    setLoadingAfterPrivyLaterCount(loadingAfterPrivyLaterCount + 1);
                }, 100);
            }
        }
        else{
            setLoadingAfterPrivy(false);
        }
    }, [loadingAfterPrivyLaterCount])

    return (
        <>
            {
                loadingAfterPrivy ?
                <div className={`${s.loadingWrap}`}>
                    <div className={`${s.logo}`} style={{backgroundImage: `url(/logo192.png)`}}>
                    </div>
                    <div className={`${s.text}`}>
                        {`Initializing.${updateLoadingCount%3>=1?'.':''}${updateLoadingCount%3>=2?'.':''}`}
                    </div>
                </div> :
                <div className={`${s.loginWrap } ${isPc ? s.pc : s.other}`}>
                    <video className={s.bg}
                        autoPlay={true} width={'100%'} height={'100%'}  muted loop playsInline
                        webkit-playsinline="true" x5-playsinline="true" x-webkit-airplay="deny" webkitwirelessvideoplaybackdisabled="true">
                        <source src={isPc?SiteBG:MobileBG} type="video/mp4" />
                    </video>
        
                    <div className={`${s.header} flex_center_between`}>
                        <CustomIcon imgName={'Picture/UI_Picture-TURNUP_Tilted_01'} width={isPc ? 480 : 194} height={isPc ? 74 : 26}></CustomIcon>
                        <div className={`flex_center_end`}>
                            {/* <a href="https://www.google.com">
                                <CustomIcon className={isPc ? 'mr40' : 'mr10'} imgName={'Button/UI_Button_White_Discard_01'}  width={isPc ? 56 : 28} height={isPc ? 40 : 20}></CustomIcon>
                            </a> */}
                            <a href="https://t.me/TURNUPDOTXYZ">
                                <CustomIcon className={isPc ? 'mr40' : 'mr10'} imgName={'Button/UI_Button_Black_TurnUp_01'} width={isPc ? 48 : 24} height={isPc ? 40 : 20}></CustomIcon>
                            </a>
                            <a href="https://twitter.com/turnupdotxyz">
                                <CustomIcon className="" imgName={'Button/UI_Button_Black_Twitter_01'} width={isPc ? 44 : 22} height={isPc ? 40 : 20}></CustomIcon>
                            </a>
                            {
                                isPc &&
                                <div className={`${s.headerStartBtn} flex_center_center`} onClick={() => {
                                    startFn();
                                }}>
                                    {t('Start!')}
                                </div>
                            }
                        </div>
                    </div>
                    {
                        !isPc && 
                        <div className={`${s.midStartBtnRoot} flex_center_end_col`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_login_bottom.png)`}}>
                            <div className={`${s.midStartBtn} flex_center_center`} onClick={() => {
                                startFn();
                            }}>
                                {t('Start!')}
                            </div>
                        </div>
                    }
        
                    <AntModal
                        width='330px'
                        title={iOSNotSafari ? t('WRONG BROWSER!') : t('Add to home screen page')}
                        className="confirmModalWrap"
                        centered={true}
                        open={showTips}
                        destroyOnClose={true}
                        onOk={() => setShowTips(false)}
                        onCancel={() => setShowTips(false)}
                    >
                        <HomeTips close={() => setShowTips(false)} setSkip={setSkip} iOSNotSafari={iOSNotSafari} tipsStr={'System Update Required'}/>
                    </AntModal>
        
                    <AntModal
                        width='495px'
                        title={``}
                        className={`momentModalWrap noPaddingModal noBg noCloseBtn`}
                        centered={true}
                        open={showInstallTips}
                        destroyOnClose={true}
                        onOk={closeAInstall}
                        onCancel={closeAInstall}
                    >
                        <PcPWAIntroNew
                            closeAInstall={closeAInstall} />
                    </AntModal>
                </div>
            }
        </>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        hideNSFW: app.hideNSFW,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setWebIMUserInfo: (params) => {
            return dispatch({
                type: "app/setWebIMUserInfo",
                payload: params
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params
            });
        },
        setInitTopUpAmount: (params) => {
            return dispatch({
                type: "app/setInitTopUpAmount",
                payload: params
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(LoginNew));
