import React from "react";
import MemeChainToken from "@/utils/json/memeChainToken.json";

const keepList = {
    "matic": "MATIC",
    "lfg": "LFG",
    "nft": "NFT",
    "nfts": "NFTs",
    "turnup": "TURNUP",
    "$up": "$UP",
    "bp": "BP",
    "id": "ID",
    "cz": "CZ",
    "iykyk":"IYKYK",
    "$iykyk":"$IYKYK",
    "dna":"DNA",
    "at": "at",
    "in": "in",
    "for": "for",
    "of": "of",
    "on": "on",
    "from": "from",
    "by": "by",
    "between": "between",
    "after": "after",
    "before": "before",
    "near": "near",
    "under": "under",
    "since": "since",
    "video": "Video",
}

if (MemeChainToken?.length > 0){
    for (let i = 0; i < MemeChainToken.length; i++){
        if (MemeChainToken[i].coinName && !keepList[MemeChainToken[i].coinName]){
            keepList[MemeChainToken[i].coinName.toLowerCase()] = MemeChainToken[i].coinName;
        }
        if (MemeChainToken[i].displayCoinName && !keepList[MemeChainToken[i].displayCoinName]){
            keepList[MemeChainToken[i].displayCoinName.toLowerCase()] = MemeChainToken[i].displayCoinName;
        }
    }
}

export function AutoStrangeRule(children, onlyFirstWord = true) {
    if (typeof children === 'undefined' || children === null) {

    } else if (typeof children === 'string') {
        return stringProcess(children, onlyFirstWord);
    } else {
        return <StrangeRule onlyFirstWord={onlyFirstWord}>{children}</StrangeRule>;
    }
}

const stringProcess = (text, onlyFirstWord) => {
    const content = keepCase(text.toLowerCase());

    if (onlyFirstWord && content.length > 0) {
        return content.charAt(0).toUpperCase() + content.slice(1);
    }

    return capitalizeEachWord(content)
}

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

const keepCase = (text) => {
    const words = Object.keys(keepList).map(escapeRegExp);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    return text.replace(regex, (matched) => keepList[matched.toLowerCase()]);
}

const capitalizeEachWord = (string) => {
    return string.split(' ').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

class StrangeRule extends React.Component {
    renderChildren = (children, onlyFirstWord) => {
        return React.Children.map(children, child => {
            if (typeof child === 'string') {
                return stringProcess(child, onlyFirstWord);
            }

            if (React.isValidElement(child) && child.props.children) {
                return React.cloneElement(child, {}, this.renderChildren(child.props.children, onlyFirstWord));
            }

            return child;
        });
    };

    render() {
        return <>{this.renderChildren(this.props.children, this.props.onlyFirstWord)}</>;
    }
}

export default StrangeRule;
