const currentEnv = 'abs_rel'; //dev:开发服, test:测试服/演示服, release:正式服 publish :上线前预演环境， polygondev ：最新dev开发环境
const closeAddScreen = true; //关闭添加到主屏幕  true ：关闭， false ：默认打开
const localurl = window.location.origin + '/';
const version = '0.0.49';
const tweettopic = '#TryTurnUp #TurnUpApp';
const tweetVal = ' #validation'
const tweettopicPre = 'link_code-';
const tweetCard = 'summary_large_image';
const tweetSite = 'GoTurnUp';
const supportedLng = ['zh', 'en', 'zh-CN', 'zh-HK', 'zh-TW', 'zh-SG'];
const popupTriviaAutoOpenIntervalTime = 30 * 60;//限时弹窗弹出间隔(s)
const popupTriviaNeedTask = 2001;
const popupTriviaStartTime = 946656000;
const popupTriviaEndTime = 1893427200;
const displayNewbieTaskStartId = 2002;
const displayLootRefundTime = 10;//劫掠失败显示返现时间
const displayLFGRefundTime = 10;//提现失败显示返现时间
const displayLFGPoolRefundTime = 10;//缓释池失败显示返现时间
const displayVLFGStakeRetryTime = 10;//二选一失败显示重试时间
const displayUnlockMissionRefundTime = 5;//解锁工作失败显示返现时间
const moonpayBasicDollar = 30;//moonpay充值至少$30
const maxMemeUnpackNftCount = 250;//meme开包nft上限
export const twitterShareEndTime = 1711900799;

export const redPacketMaxQuantity = 200;

const envObj = {
    publish: {
        baseUrl: 'https://badass-uw-test-apiv2.badass.xyz:50190',  //appServer
        rtc_appid: 'bd891d48191e46dfa89a1203cd1f2c7e', //声网APPkey
        webIMappKey: '1101230228168766#turnup-release', //环信开发账号
        depositUrl: 'https://deposit-dev.turnup.xyz/', //充值页面 已废弃
        tweetClientId: 'V3VMMmE2dXVpa2dVNzNFZ1lhZnM6MTpjaQ',
        taEnable: true,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '9e5faf84e46f4342934538cb65b87d4d',
        taRegionId: '3',
        privyAppId: 'clmv1n1io058sla0fn4zm3k2i',
        online_env: false,
    },
    dev: {
        baseUrl: 'https://badass-uw-test-apiv2.badass.xyz:50082',
        rtc_appid: '30ef10730a284f839db9b6a30ea8bcb3',
        webIMappKey: '1101230228168766#turnup-test',
        depositUrl: 'https://deposit-dev.turnup.xyz/',
        tweetClientId: 'V3VMMmE2dXVpa2dVNzNFZ1lhZnM6MTpjaQ',
        taEnable: false,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '9e5faf84e46f4342934538cb65b87d4d',
        taRegionId: '0',
        privyAppId: 'clmv1n1io058sla0fn4zm3k2i',
        online_env: false,
    },
    test: {
        baseUrl: 'https://badass-uw-test-apiv2.badass.xyz:50282',//已更新为polygon版演示服的端口
        rtc_appid: '181028fa86054a4a8840a0c854dd6fae',
        webIMappKey: '1101230228168766#bytecity-test-roadshow',
        depositUrl: 'https://deposit-test.turnup.xyz/',
        tweetClientId: 'V3VMMmE2dXVpa2dVNzNFZ1lhZnM6MTpjaQ',
        taEnable: true,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '9e5faf84e46f4342934538cb65b87d4d',
        taRegionId: '21',
        privyAppId: 'clmvnoiv605z8mb0fw6t2ivip',        //test privy APP ID
        online_env: true,
    },
    release: {
        baseUrl: 'https://turnup-uw-test-apiv2.turnup.xyz',
        rtc_appid: 'bd891d48191e46dfa89a1203cd1f2c7e',
        webIMappKey: '1101230228168766#bytecity',
        depositUrl: 'https://deposit.turnup.xyz/',
        tweetClientId: 'SGVsM1RPcmlnVHE4WFh4Z2xFaDc6MTpjaQ',
        taEnable: true,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '16bcc071b89c4056abcdf522e6ddb33c',
        taRegionId: '11',
        privyAppId: 'clmv1n1io058sla0fn4zm3k2i',
        rtc_live_b: true,
        rtc_live_d: 3,
        rtc_live_i: 30000,
        rtc_live_poster: 'https://turnup-uw-test-apiv2.turnup.xyz:48150/poster',
        online_env: true,
    },
    polygondev: {
        baseUrl: 'https://badass-uw-test-apiv2.badass.xyz:50280',  //appServer
        rtc_appid: '599e4b0d9e04440eafa40a766a28dfbc',
        webIMappKey: '1101230228168766#turnup-polygon-dev',
        depositUrl: 'https://deposit-dev.turnup.xyz/',
        tweetClientId: 'V3VMMmE2dXVpa2dVNzNFZ1lhZnM6MTpjaQ',
        taEnable: true,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '9e5faf84e46f4342934538cb65b87d4d',
        taRegionId: '19',
        privyAppId: 'clmv1n1io058sla0fn4zm3k2i',
        rtc_live_b: true,
        rtc_live_d: 3,
        rtc_live_i: 30000,
        rtc_live_poster: 'https://badass-uw-test-apiv2.badass.xyz:48151/poster',
        online_env: false,
    },
    abs_rel: {
        baseUrl: 'https://badass-uw-test-apiv2.badass.xyz:50280',  //appServer
        rtc_appid: 'bd891d48191e46dfa89a1203cd1f2c7e', //声网APPkey
        webIMappKey: '1101230228168766#turnup-release', //环信开发账号
        depositUrl: 'https://deposit-dev.turnup.xyz/', //充值页面 已废弃
        tweetClientId: 'V3VMMmE2dXVpa2dVNzNFZ1lhZnM6MTpjaQ',
        taEnable: true,
        taPushUrl: 'https://ta.turnup.xyz',
        taAppId: '9e5faf84e46f4342934538cb65b87d4d',
        taRegionId: '3',
        privyAppId: 'clmv1n1io058sla0fn4zm3k2i',
        online_env: true,
    },
}

const LfgMainnetId = 13701;
const LfgTestnetId = 8000201;

const MaticMainnetId = 13700;
const MaticTestnetId = 8000200;

const PolygonChainId = 137;
const AmoyChainId = 80002;
const BaseChainId = 8453;
const BaseSepoliaChainId = 84532;

//mainnet
const PolygonRPC = 'https://polygon-mainnet.g.alchemy.com/v2/Ss3bwjRGwXmtSmBCLC5Xy4B2h7KT3kRM';
const BaseRPC = 'https://base-mainnet.g.alchemy.com/v2/K1aSwUpgvAs-F_s4YK1mrqnVwoKs7bv_';

//testnet
const PolygonAmoyRPC = 'https://polygon-amoy.g.alchemy.com/v2/aFijVx0MWIUKN8K2mXHWG2NedhKvZQyO'; 
const BaseSepoliaRPC = 'https://base-sepolia.g.alchemy.com/v2/cYLBC41-SeVgF6UJGSbCX3ZGIw7eKxCD';

const errorCode = [
    {
        "id": 0,
        "code": "opCodeNoErr",
        "tips": "opCodeNoErr"
    },
    {
        "id": 1,
        "code": "opCodeParameter",
        "tips": "opCodeParameter"
    },
    {
        "id": 2,
        "code": "opCodeToken",
        "tips": "opCodeToken"
    },
    {
        "id": 3,
        "code": "opCodeDB",
        "tips": "opCodeDB"
    },
    {
        "id": 4,
        "code": "opCodeInternal",
        "tips": "opCodeInternal"
    },
    {
        "id": 100,
        "code": "opCodeKeyBuyParameter",
        "tips": "Parameter error"
    },
    {
        "id": 101,
        "code": "opCodeKeyBuySellerNotFound",
        "tips": "Seller parameter error"
    },
    {
        "id": 200,
        "code": "opCodeTopUpCodeNotFound",
        "tips": "Recharge code not found"
    },
    {
        "id": 201,
        "code": "OpCodeRetryLater",
        "tips": "Please try again later"
    },
    {
        "id": 202,
        "code": "OpCodeBuyFirstKeyNotSucc",
        "tips": "Failed to purchase the first key"
    },
    {
        "id": 301,
        "code": "opCodeInviteCodeErr",
        "tips": "Invalid invitation code"
    },
    {
        "id": 302,
        "code": "opCodeInviteCodeIsUsed",
        "tips": "This invitation code has already been used"
    },
    {
        "id": 303,
        "code": "opCodeInviteCodeHasChecked",
        "tips": "The invitation code has been confirmed; please avoid duplicate verification"
    },
    {
        "id": 304,
        "code": "opCodeUserIdNotExist",
        "tips": "User ID does not exist"
    },
    {
        "id": 305,
        "code": "opCodeSellKeyNotEnough",
        "tips": "Insufficient keys"
    },
    {
        "id": 306,
        "code": "opCodeTradeNotHaveWallet",
        "tips": "Transaction wallet parameter error"
    },
    {
        "id": 307,
        "code": "opCodeTradeNotAllowed",
        "tips": "Transaction rejected"
    },
    {
        "id": 308,
        "code": "opCodeNotEnoughBalance",
        "tips": "Insufficient balance"
    },
    {
        "id": 309,
        "code": "opCodeLastKeyCantSell",
        "tips": "It is not allowed to sell the last key"
    },
    {
        "id": 310,
        "code": "opCodeInitAccountFirst",
        "tips": "Account initialization error"
    },
    {
        "id": 311,
        "code": "opCodeCanotJoinAgoraChannel",
        "tips": "Error when joining the live streaming room"
    },
    {
        "id": 312,
        "code": "opCodeNotHoldSelfKey",
        "tips": "You must have at least one key to continue"
    },
    {
        "id": 313,
        "code": "opCodeTwitterGetTokenErr",
        "tips": "Error acquiring Twitter token"
    },
    {
        "id": 314,
        "code": "opCodeTwitterGetUserErr",
        "tips": "Error acquiring Twitter user information"
    },
    {
        "id": 315,
        "code": "opCodeTwitterChallengeExpires",
        "tips": "Twitter authentication failed. Please try again"
    },
    {
        "id": 316,
        "code": "opCodeTwitterBindNotSameAccount",
        "tips": "The linked Twitter account does not match"
    },
    {
        "id": 317,
        "code": "opCodeTwitterAccountAlreadyBind",
        "tips": "This Twitter account has already been linked"
    },
    {
        "id": 318,
        "code": "OpCodeTwitterCodeIsExpired",
        "tips": "Twitter binding link has expired"
    },
    {
        "id": 328,
        "code": "Invalid Code",
        "tips": "Invalid Code"
    },
    {
        "id": 330,
        "code": "",
        "tips": "The account does not exist"
    },

    {
        "id": 401,
        "code": "opCodeTradeCodeFailed",
        "tips": "On-chain transaction failed."
    },
    {
        "id": 402,
        "code": "opCodeTradeCodePending",
        "tips": "On-chain transactions are currently suspended."
    },
    {
        "id": 403,
        "code": "opCodeTradeCodeAbord",
        "tips": "On-chain transaction error."
    }
]

const bsc = {
    id: 56,
    name: "BNB Smart Chain",
    network: "bsc",
    nativeCurrency: {
        decimals: 18,
        name: "BNB",
        symbol: "BNB"
    },
    rpcUrls: {
        default: {
            http: ["https://bsc-mainnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        },
        public: {
            http: ["https://bsc-mainnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        }
    },
    blockExplorers: {
        etherscan: {
            name: "BscScan",
            url: "https://bscscan.com",
        },
        default: {
            name: "BscScan",
            url: "https://bscscan.com",
        }
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 15921452,
        }
    }
};

const bscTestnet = {
    id: 97,
    name: "Binance Smart Chain Testnet",
    network: "bsc-testnet",
    nativeCurrency: {
        decimals: 18,
        name: "BNB",
        symbol: "tBNB",
    },
    rpcUrls: {
        default: {
            http: ["https://bsc-testnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        },
        public: {
            http: ["https://bsc-testnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "BscScan",
            url: "https://testnet.bscscan.com",
        },
        default: {
            name: "BscScan",
            url: "https://testnet.bscscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 17422483,
        },
    },
    testnet: true,
};

const opBNB = {
    id: 204,
    name: "opBNB Mainnet",
    network: "opBNB",
    nativeCurrency: {
        decimals: 18,
        name: "BNB",
        symbol: "BNB"
    },
    rpcUrls: {
        default: {
            http: ["https://opbnb-mainnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        },
        public: {
            http: ["https://opbnb-mainnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        }
    },
    blockExplorers: {
        default: {name: "opBNBScan", url: "http://mainnet.opbnbscan.com/"}
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 512881
        }
    },
};

const platformObj = [
    {
        label: "X(Twitter)",
        type: 1,
        iconName: 'Button/UI_Button_Ball-White_Twitter_On'
    },
    {
        label: "Tiktok",
        type: 2,
        iconName: 'tiktokMaskImage'
    },
]

const opBNBTestnet = {
    id: 5611,
    name: "opBNB Testnet",
    network: "opBNB-test",
    nativeCurrency: {
        decimals: 18,
        name: "tBNB",
        symbol: "tBNB"
    },
    rpcUrls: {
        default: {
            http: ["https://opbnb-testnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        },
        public: {
            http: ["https://opbnb-testnet.nodereal.io/v1/8820ced7fdad40fc95e1d37d1f1a08e5"],
        }
    },
    blockExplorers: {
        default: {name: "opBNB-testScan", url: "https://opbnb-testnet.bscscan.com"}
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 3705108
        }
    },
    testnet: true
};


const envConfig = envObj[currentEnv];
const tweetRedirectUrl = envObj[currentEnv]?.baseUrl + '/twittercallback';
const tiktokRedirectUrl = envObj[currentEnv]?.baseUrl + '/tiktokcallback';
const isOnlineEnv = function () {
    return envConfig.online_env === true; 
}

export {
    envConfig,
    isOnlineEnv,
    localurl,
    closeAddScreen,
    tweetRedirectUrl,
    tiktokRedirectUrl,
    errorCode,
    tweettopic,
    tweettopicPre,
    bsc,
    bscTestnet,
    version,
    opBNB,
    opBNBTestnet,
    tweetVal,
    platformObj,
    tweetCard,
    tweetSite,
    supportedLng,
    popupTriviaAutoOpenIntervalTime,
    popupTriviaNeedTask,
    popupTriviaStartTime,
    popupTriviaEndTime,
    displayNewbieTaskStartId,
    displayLootRefundTime,
    displayLFGRefundTime,
    displayLFGPoolRefundTime,
    displayVLFGStakeRetryTime,
    displayUnlockMissionRefundTime,
    PolygonRPC,
    PolygonAmoyRPC,
    BaseRPC,
    BaseSepoliaRPC,
    LfgMainnetId,
    LfgTestnetId,
    MaticMainnetId,
    MaticTestnetId,
    PolygonChainId,
    AmoyChainId,
    BaseChainId,
    BaseSepoliaChainId,
    maxMemeUnpackNftCount,
    moonpayBasicDollar,
}
